import {
  createSubscription,
  updateSubscription,
  useGetSubscription,
  useListSubscriptions,
} from 'api/admin';
import { FilterableTable } from 'components/tables/filterable-table';
import { format } from 'date-fns';
import {
  Badge,
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  DatePicker,
  FormField,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
} from '@cloudscape-design/components';
import CopyText from 'components/copy-text';
import { ValueWithLabel } from 'components/value-with-label';
import { ActionButton } from 'pages/upgrade_plans/helpers';
import { useEffect, useState } from 'react';
import {
  PlanUsageLimitType,
  ResolvedEntitlement,
  SubscriptionPlan,
  SubscriptionType,
} from 'api/admin-models';

export function OrganizationSubscriptions(props: {
  orgId: string;
  setError?: (error: string) => void;
}): JSX.Element {
  const [subscriptionCreatorVisible, setSubscriptionCreatorVisible] =
    useState(false);
  const { data, mutate } = useListSubscriptions(props.orgId);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
    string | undefined
  >(undefined);

  return (
    <Container>
      <SpaceBetween direction="vertical" size="m">
        <FilterableTable
          selectionMode="single"
          data={data?.data}
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <AccountSubscriptionCreator
                    accountId={props.orgId}
                    onConfirm={() => setSubscriptionCreatorVisible(false)}
                    onDismiss={() => setSubscriptionCreatorVisible(false)}
                    visible={subscriptionCreatorVisible}
                    setError={props.setError}
                  />
                  <ActionButton
                    key="create-subscription"
                    onClick={() => setSubscriptionCreatorVisible(true)}
                  >
                    Create Subscription
                  </ActionButton>
                  <Button
                    iconName="refresh"
                    variant="icon"
                    onClick={() => void mutate()}
                  />
                </SpaceBetween>
              }
            >
              Subscriptions
            </Header>
          }
          columns={[
            {
              id: 'Id',
              label: 'Subscription ID',
              cell: (item) => item.subscription_id,

              defaultVisible: true,
              alwaysVisible: true,

              sortingField: 'id',
            },
            {
              id: 'Name',
              label: 'Name',
              cell: (item) => item.name || '-',

              defaultVisible: true,

              sortingField: 'name',
              filterOperators: ['=', '!=', ':', '!:'],
            },
            {
              id: 'Plan',
              label: 'Plan',
              cell: (item) => item.plan,

              defaultVisible: true,

              sortingField: 'plan',
              filterOperators: ['=', '!=', ':', '!:'],
            },
            {
              id: 'Type',
              label: 'Type',
              cell: (item) => item.type,

              defaultVisible: true,

              sortingField: 'type',
              filterOperators: ['=', '!=', ':', '!:'],
            },
            {
              id: 'Created',
              label: 'Created',
              cell: (item) => format(item.created * 1000, 'Pp'),

              defaultVisible: true,

              sortingField: 'created',
              filterOperators: ['=', '!=', '<', '>', '<=', '>='],
            },
            {
              id: 'Started',
              label: 'Started',
              cell: (item) => {
                if (!item.started) {
                  return '-';
                }

                return format(item.started * 1000, 'Pp');
              },

              defaultVisible: true,

              sortingField: 'started',
              filterOperators: ['=', '!=', '<', '>', '<=', '>='],
            },
            {
              id: 'Expiry',
              label: 'Expiry',
              cell: (item) => {
                if (!item.expiry) {
                  return '-';
                }

                return format(item.expiry * 1000, 'Pp');
              },

              defaultVisible: true,

              sortingField: 'expiry',
              filterOperators: ['=', '!=', '<', '>', '<=', '>='],
            },
            {
              id: 'Revocation',
              label: 'Revocation',
              cell: (item) => {
                if (!item.revocation) {
                  return '-';
                }

                return format(item.revocation * 1000, 'Pp');
              },

              defaultVisible: true,

              sortingField: 'revocation',
              filterOperators: ['=', '!=', '<', '>', '<=', '>='],
            },
          ]}
          selectionTrackBy={(item) => item.subscription_id}
          onSelectionChange={(event) =>
            setSelectedSubscriptionId(
              event.detail.selectedItems[0].subscription_id,
            )
          }
        />
        {selectedSubscriptionId && (
          <OrganizationSubscriptionDetail
            orgId={props.orgId}
            subscriptionId={selectedSubscriptionId}
            setError={props.setError}
          />
        )}
      </SpaceBetween>
    </Container>
  );
}

function OrganizationSubscriptionDetail(props: {
  orgId: string;
  subscriptionId: string;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data: subscription } = useGetSubscription(
    props.orgId,
    props.subscriptionId,
  );
  const [showEndDatesSelector, setShowEndDatesSelector] = useState(false);
  const [nodeCountAdjustor, setNodeCountAdjustor] = useState(false);
  const [upgradePlanCountAdjustor, setUpgradePlanCountAdjustor] =
    useState(false);
  const [upgradeTemplateCountAdjustor, setUpgradeTemplateCountAdjustor] =
    useState(false);
  const [clusterCountAdjustor, setClusterCountAdjustor] = useState(false);

  return (
    <>
      <AccountSubscriptionEndDatesSelector
        accountId={props.orgId}
        subscriptionId={props.subscriptionId}
        onConfirm={() => setShowEndDatesSelector(false)}
        onDismiss={() => setShowEndDatesSelector(false)}
        visible={showEndDatesSelector}
        setError={props.setError}
      />
      {/* TODO: dedup adjustors */}
      <AccountSubscriptionNodeCountAdjustor
        accountId={props.orgId}
        subscriptionId={props.subscriptionId}
        onConfirm={() => setNodeCountAdjustor(false)}
        onDismiss={() => setNodeCountAdjustor(false)}
        visible={nodeCountAdjustor}
        setError={props.setError}
      />
      <AccountSubscriptionUpgradePlanCountAdjustor
        accountId={props.orgId}
        subscriptionId={props.subscriptionId}
        onConfirm={() => setUpgradePlanCountAdjustor(false)}
        onDismiss={() => setUpgradePlanCountAdjustor(false)}
        visible={upgradePlanCountAdjustor}
        setError={props.setError}
      />
      <AccountSubscriptionUpgradeTemplateCountAdjustor
        accountId={props.orgId}
        subscriptionId={props.subscriptionId}
        onConfirm={() => setUpgradeTemplateCountAdjustor(false)}
        onDismiss={() => setUpgradeTemplateCountAdjustor(false)}
        visible={upgradeTemplateCountAdjustor}
        setError={props.setError}
      />
      <AccountSubscriptionClusterCountAdjustor
        accountId={props.orgId}
        subscriptionId={props.subscriptionId}
        onConfirm={() => setClusterCountAdjustor(false)}
        onDismiss={() => setClusterCountAdjustor(false)}
        visible={clusterCountAdjustor}
        setError={props.setError}
      />
      <SpaceBetween size="l">
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <ActionButton
                key="update-expiry"
                onClick={() => setShowEndDatesSelector(true)}
              >
                Update Subscription Dates
              </ActionButton>
            </SpaceBetween>
          }
        >
          Subscription Details
        </Header>
        <ColumnLayout columns={3} variant="text-grid">
          <ValueWithLabel label="Account ID">
            <CopyText
              copyText={subscription?.org_id || ''}
              copyButtonLabel="Copy Account ID"
              successText="Account ID copied"
              errorText="Account ID failed to copy"
            />
          </ValueWithLabel>
          <ValueWithLabel label="ID">
            <CopyText
              copyText={subscription?.subscription_id || ''}
              copyButtonLabel="Copy Subscription ID"
              successText="Subscription ID copied"
              errorText="Subscription ID failed to copy"
            />
          </ValueWithLabel>
          <ValueWithLabel label="Name">
            {subscription?.name || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Plan">
            {subscription?.plan || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Type">
            {subscription?.type || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Created">
            {subscription?.created && format(subscription.created * 1000, 'Pp')}
          </ValueWithLabel>
          <ValueWithLabel label="Started">
            {subscription?.started
              ? format(subscription.started * 1000, 'Pp')
              : '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Expiry">
            {subscription?.expiry
              ? format(subscription.expiry * 1000, 'Pp')
              : '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Revocation">
            {subscription?.revocation
              ? format(subscription.revocation * 1000, 'Pp')
              : '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Features">
            <SpaceBetween direction="horizontal" size="xs">
              {(subscription?.entitlement?.features || []).includes(
                'request_addon',
              ) && <Badge>Request Addon</Badge>}
              {(subscription?.entitlement?.features || []).includes(
                'request_cloud',
              ) && <Badge>Request Cloud</Badge>}
            </SpaceBetween>
          </ValueWithLabel>
        </ColumnLayout>
        <Header variant="h2">Entitlement</Header>
        <ColumnLayout columns={3} variant="text-grid">
          <Container
            header={
              <Header
                variant="h3"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <ActionButton
                      key="update-node-count"
                      onClick={() => setNodeCountAdjustor(true)}
                    >
                      Adjust
                    </ActionButton>
                  </SpaceBetween>
                }
              >
                Node Usage
              </Header>
            }
          >
            <ValueWithLabel label="Count">
              {subscription?.entitlement?.node_count.limit}
            </ValueWithLabel>
            <ValueWithLabel label="Limit Type">
              {subscription?.entitlement?.node_count.type}
            </ValueWithLabel>
          </Container>
          <Container
            header={
              <Header
                variant="h3"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <ActionButton
                      key="update-upgrade-plan-count"
                      onClick={() => setUpgradePlanCountAdjustor(true)}
                    >
                      Adjust
                    </ActionButton>
                  </SpaceBetween>
                }
              >
                Upgrade Plan Usage
              </Header>
            }
          >
            <ValueWithLabel label="Count">
              {subscription?.entitlement?.upgrade_plan_count !== undefined
                ? subscription.entitlement.upgrade_plan_count.limit
                : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Limit Type">
              {subscription?.entitlement?.upgrade_plan_count !== undefined
                ? subscription.entitlement.upgrade_plan_count.type
                : '-'}
            </ValueWithLabel>
          </Container>
          <Container
            header={
              <Header
                variant="h3"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <ActionButton
                      key="update-upgrade-template-count"
                      onClick={() => setUpgradeTemplateCountAdjustor(true)}
                    >
                      Adjust
                    </ActionButton>
                  </SpaceBetween>
                }
              >
                Upgrade Template Usage
              </Header>
            }
          >
            <ValueWithLabel label="Count">
              {subscription?.entitlement?.upgrade_template_count !== undefined
                ? subscription.entitlement.upgrade_template_count.limit
                : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Limit Type">
              {subscription?.entitlement?.upgrade_template_count !== undefined
                ? subscription.entitlement.upgrade_template_count.type
                : '-'}
            </ValueWithLabel>
          </Container>
          <Container
            header={
              <Header
                variant="h3"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <ActionButton
                      key="update-cluster-count"
                      onClick={() => setClusterCountAdjustor(true)}
                    >
                      Adjust
                    </ActionButton>
                  </SpaceBetween>
                }
              >
                Cluster Usage
              </Header>
            }
          >
            <ValueWithLabel label="Count">
              {subscription?.entitlement?.cluster_count !== undefined
                ? subscription.entitlement.cluster_count.limit
                : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Limit Type">
              {subscription?.entitlement?.cluster_count !== undefined
                ? subscription.entitlement.cluster_count.type
                : '-'}
            </ValueWithLabel>
          </Container>
        </ColumnLayout>
      </SpaceBetween>
    </>
  );
}

function AccountSubscriptionEndDatesSelector(props: {
  accountId: string;
  subscriptionId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data: subscription, mutate } = useGetSubscription(
    props.accountId,
    props.subscriptionId,
  );
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [expiryDate, setExpiryDate] = useState<Date | undefined>(undefined);
  const [revocationDate, setRevocationDate] = useState<Date | undefined>(
    undefined,
  );

  useEffect(() => {
    if (subscription?.expiry) {
      const date = new Date(subscription.expiry * 1000);
      setExpiryDate(date);
    }
    if (subscription?.revocation) {
      const date = new Date(subscription.revocation * 1000);
      setRevocationDate(date);
    }
  }, [subscription, props.visible]);

  const [processing, setProcessing] = useState(false);

  const onConfirmClick = async () => {
    setProcessing(true);
    try {
      await updateSubscription(props.accountId, props.subscriptionId, {
        expiry: expiryDate ? Math.ceil(expiryDate.getTime() / 1000) : 0,
        revocation: revocationDate
          ? Math.ceil(revocationDate.getTime() / 1000)
          : 0,
        started: startDate ? Math.ceil(startDate.getTime() / 1000) : 0,
      });
      await mutate();
      props.onConfirm();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      props.onDismiss();
    }
    setProcessing(false);
  };

  return (
    <Modal
      header={`Update Subscription Dates`}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <SpaceBetween direction="vertical" size="m">
        <ColumnLayout>
          <Container header={<Header variant="h3">Start</Header>}>
            <ValueWithLabel label="Current Start Date">
              {subscription?.started
                ? format(subscription.started * 1000, 'P')
                : '-'}
            </ValueWithLabel>

            <FormField label="Date" constraintText="Use YYYY/MM/DD format.">
              <DatePicker
                value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
                onChange={(event) => {
                  if (event.detail.value === '') {
                    setStartDate(undefined);
                  } else {
                    setStartDate(new Date(event.detail.value));
                  }
                }}
                placeholder={'None'}
              />
            </FormField>
          </Container>
          <Container header={<Header variant="h3">Expiry</Header>}>
            <ValueWithLabel label="Current Expiry">
              {subscription?.expiry
                ? format(subscription.expiry * 1000, 'P')
                : '-'}
            </ValueWithLabel>

            <FormField label="Date" constraintText="Use YYYY/MM/DD format.">
              <DatePicker
                value={expiryDate ? format(expiryDate, 'yyyy-MM-dd') : ''}
                onChange={(event) => {
                  if (event.detail.value === '') {
                    setExpiryDate(undefined);
                  } else {
                    setExpiryDate(new Date(event.detail.value));
                  }
                }}
                placeholder={'None'}
              />
            </FormField>
          </Container>
          <Container header={<Header variant="h3">Revocation</Header>}>
            <ValueWithLabel label="Current Revocation">
              {subscription?.revocation
                ? format(subscription.revocation * 1000, 'P')
                : '-'}
            </ValueWithLabel>
            <FormField label="Date" constraintText="Use YYYY/MM/DD format.">
              <DatePicker
                value={
                  revocationDate ? format(revocationDate, 'yyyy-MM-dd') : ''
                }
                onChange={(event) => {
                  if (event.detail.value === '') {
                    setRevocationDate(undefined);
                  } else {
                    setRevocationDate(new Date(event.detail.value));
                  }
                }}
                placeholder={'None'}
              />
            </FormField>
          </Container>
        </ColumnLayout>
        <SpaceBetween direction="horizontal" size="xs">
          <ActionButton
            key="confirm"
            onConfirm={onConfirmClick}
            disabled={processing}
          >
            Confirm
          </ActionButton>
          <ActionButton key="cancel" onClick={props.onDismiss}>
            Cancel
          </ActionButton>
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
}

function AccountSubscriptionNodeCountAdjustor(props: {
  accountId: string;
  subscriptionId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data, mutate } = useListSubscriptions(props.accountId);
  const subscription = data?.data.find(
    (s) => s.subscription_id === props.subscriptionId,
  );

  const [nodeCountLimit, setNodeCountLimit] = useState(
    subscription?.entitlement.node_count?.limit || 0,
  );
  const [nodeCountType, setNodeCountType] = useState<{
    label?: string;
    value?: string;
  }>({
    label: subscription?.entitlement.node_count?.type || 'Hard',
    value: subscription?.entitlement.node_count?.type || 'Hard',
  });

  useEffect(() => {
    if (subscription?.entitlement?.node_count) {
      setNodeCountLimit(subscription.entitlement.node_count.limit);
      setNodeCountType({
        label: subscription.entitlement.node_count.type,
        value: subscription.entitlement.node_count.type,
      });
    } else {
      setNodeCountLimit(0);
      setNodeCountType({
        label: 'Hard',
        value: 'Hard',
      });
    }
  }, [subscription, props.visible]);

  const onConfirmClick = async () => {
    try {
      await updateSubscription(props.accountId, props.subscriptionId, {
        entitlement: {
          node_count: {
            limit: nodeCountLimit,
            type: nodeCountType.value as PlanUsageLimitType,
          },
        },
      });
      await mutate();
      props.onConfirm();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      props.onDismiss();
    }
  };

  return (
    <ProcessingDialogModal
      header={`Update Node Count`}
      onConfirm={onConfirmClick}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <Container>
        <ResolvedEntitlementAdjustor
          limit={nodeCountLimit.toString()}
          limitOnChange={setNodeCountLimit}
          type={nodeCountType}
          typeOnChange={setNodeCountType}
        />
      </Container>
    </ProcessingDialogModal>
  );
}

function ProcessingDialogModal(props: {
  header: string;
  onConfirm: () => Promise<void>;
  onDismiss: () => void;
  visible: boolean;
  children: React.ReactNode;
}): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onConfirmClick = async () => {
    setProcessing(true);
    try {
      await props.onConfirm();
    } catch (err: unknown) {
      console.error(err);
    }
    setProcessing(false);
  };

  return (
    <Modal
      header={props.header}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <SpaceBetween direction="vertical" size="m">
        {props.children}
        <SpaceBetween direction="horizontal" size="xs">
          <ActionButton
            key="confirm"
            onConfirm={onConfirmClick}
            disabled={processing}
          >
            Confirm
          </ActionButton>
          <ActionButton key="cancel" onClick={props.onDismiss}>
            Cancel
          </ActionButton>
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
}

function AccountSubscriptionUpgradePlanCountAdjustor(props: {
  accountId: string;
  subscriptionId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data, mutate } = useListSubscriptions(props.accountId);
  const subscription = data?.data.find(
    (s) => s.subscription_id === props.subscriptionId,
  );

  const [upgradePlanCountLimit, setUpgradePlanCountLimit] = useState(
    subscription?.entitlement.upgrade_plan_count?.limit || 0,
  );
  const [upgradePlanCountType, setUpgradePlanCountType] = useState<{
    label?: string;
    value?: string;
  }>({
    label: subscription?.entitlement.upgrade_plan_count?.type || 'Hard',
    value: subscription?.entitlement.upgrade_plan_count?.type || 'Hard',
  });

  useEffect(() => {
    if (subscription?.entitlement?.upgrade_plan_count) {
      setUpgradePlanCountLimit(
        subscription.entitlement.upgrade_plan_count?.limit,
      );
      setUpgradePlanCountType({
        label: subscription.entitlement.upgrade_plan_count?.type,
        value: subscription.entitlement.upgrade_plan_count?.type,
      });
    } else {
      setUpgradePlanCountLimit(0);
      setUpgradePlanCountType({
        label: 'Hard',
        value: 'Hard',
      });
    }
  }, [subscription, props.visible]);

  const onConfirmClick = async () => {
    try {
      await updateSubscription(props.accountId, props.subscriptionId, {
        entitlement: {
          // FIXME: this is a hack to get around the fact that the API
          // requires node_count field to be present when updating
          node_count: subscription?.entitlement.node_count || {
            limit: 0,
            type: 'Hard',
          },
          upgrade_plan_count: {
            limit: upgradePlanCountLimit,
            type: upgradePlanCountType.value as PlanUsageLimitType,
          },
        },
      });
      await mutate();
      props.onConfirm();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      props.onDismiss();
    }
  };

  return (
    <ProcessingDialogModal
      header={`Update Upgrade Plan Count`}
      onConfirm={onConfirmClick}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <Container>
        <ResolvedEntitlementAdjustor
          limit={upgradePlanCountLimit.toString()}
          limitOnChange={setUpgradePlanCountLimit}
          type={upgradePlanCountType}
          typeOnChange={setUpgradePlanCountType}
        />
      </Container>
    </ProcessingDialogModal>
  );
}

function AccountSubscriptionUpgradeTemplateCountAdjustor(props: {
  accountId: string;
  subscriptionId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data, mutate } = useListSubscriptions(props.accountId);
  const subscription = data?.data.find(
    (s) => s.subscription_id === props.subscriptionId,
  );

  const [upgradeTemplateCountLimit, setUpgradeTemplateCountLimit] = useState(
    subscription?.entitlement.upgrade_template_count?.limit || 0,
  );
  const [upgradeTemplateCountType, setUpgradeTemplateCountType] = useState<{
    label?: string;
    value?: string;
  }>({
    label: subscription?.entitlement.upgrade_template_count?.type || 'Hard',
    value: subscription?.entitlement.upgrade_template_count?.type || 'Hard',
  });

  useEffect(() => {
    if (subscription?.entitlement?.upgrade_template_count) {
      setUpgradeTemplateCountLimit(
        subscription.entitlement.upgrade_template_count?.limit,
      );
      setUpgradeTemplateCountType({
        label: subscription.entitlement.upgrade_template_count?.type,
        value: subscription.entitlement.upgrade_template_count?.type,
      });
    } else {
      setUpgradeTemplateCountLimit(0);
      setUpgradeTemplateCountType({
        label: 'Hard',
        value: 'Hard',
      });
    }
  }, [subscription, props.visible]);

  const onConfirmClick = async () => {
    try {
      await updateSubscription(props.accountId, props.subscriptionId, {
        entitlement: {
          // FIXME: this is a hack to get around the fact that the API
          // requires node_count field to be present when updating
          node_count: subscription?.entitlement.node_count || {
            limit: 0,
            type: 'Hard',
          },
          upgrade_template_count: {
            limit: upgradeTemplateCountLimit,
            type: upgradeTemplateCountType.value as PlanUsageLimitType,
          },
        },
      });
      await mutate();
      props.onConfirm();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      props.onDismiss();
    }
  };

  return (
    <ProcessingDialogModal
      header={`Update Upgrade Template Count`}
      onConfirm={onConfirmClick}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <Container>
        <ResolvedEntitlementAdjustor
          limit={upgradeTemplateCountLimit.toString()}
          limitOnChange={setUpgradeTemplateCountLimit}
          type={upgradeTemplateCountType}
          typeOnChange={setUpgradeTemplateCountType}
        />
      </Container>
    </ProcessingDialogModal>
  );
}

function ResolvedEntitlementAdjustor(props: {
  limit: string;
  limitOnChange: (value: number) => void;
  type: { label?: string; value?: string };
  typeOnChange: (value: { label?: string; value?: string }) => void;
}): JSX.Element {
  return (
    <ColumnLayout columns={2} variant="text-grid">
      <FormField label="Limit">
        <Input
          type="number"
          value={props.limit}
          onChange={({ detail }) => props.limitOnChange(parseInt(detail.value))}
        />
      </FormField>
      <FormField label="Type">
        <Select
          selectedOption={props.type}
          onChange={({ detail }) => props.typeOnChange(detail.selectedOption)}
          options={[
            { label: 'Hard', value: 'Hard' },
            { label: 'Soft', value: 'Soft' },
          ]}
        />
      </FormField>
    </ColumnLayout>
  );
}

function AccountSubscriptionClusterCountAdjustor(props: {
  accountId: string;
  subscriptionId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data, mutate } = useListSubscriptions(props.accountId);
  const subscription = data?.data.find(
    (s) => s.subscription_id === props.subscriptionId,
  );

  const [clusterCountLimit, setClusterCountLimit] = useState(
    subscription?.entitlement.cluster_count?.limit || 0,
  );
  const [clusterCountType, setClusterCountType] = useState<{
    label?: string;
    value?: string;
  }>({
    label: subscription?.entitlement.cluster_count?.type || 'Hard',
    value: subscription?.entitlement.cluster_count?.type || 'Hard',
  });

  useEffect(() => {
    if (subscription?.entitlement?.cluster_count) {
      setClusterCountLimit(subscription.entitlement.cluster_count?.limit);
      setClusterCountType({
        label: subscription.entitlement.cluster_count?.type,
        value: subscription.entitlement.cluster_count?.type,
      });
    } else {
      setClusterCountLimit(0);
      setClusterCountType({
        label: 'Hard',
        value: 'Hard',
      });
    }
  }, [subscription, props.visible]);

  const onConfirmClick = async () => {
    try {
      await updateSubscription(props.accountId, props.subscriptionId, {
        entitlement: {
          // FIXME: this is a hack to get around the fact that the API
          // requires node_count field to be present when updating
          node_count: subscription?.entitlement.node_count || {
            limit: 0,
            type: 'Hard',
          },
          cluster_count: {
            limit: clusterCountLimit,
            type: clusterCountType.value as PlanUsageLimitType,
          },
        },
      });
      await mutate();
      props.onConfirm();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      props.onDismiss();
    }
  };

  return (
    <ProcessingDialogModal
      header={`Update Cluster Count`}
      onConfirm={onConfirmClick}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <Container>
        <ResolvedEntitlementAdjustor
          limit={clusterCountLimit.toString()}
          limitOnChange={setClusterCountLimit}
          type={clusterCountType}
          typeOnChange={setClusterCountType}
        />
      </Container>
    </ProcessingDialogModal>
  );
}

function AccountSubscriptionCreator(props: {
  accountId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  setError?: (error: string) => void;
  visible: boolean;
}): JSX.Element {
  const [inputLock, setInputLock] = useState(true);
  const [plan, setPlan] = useState<{
    label?: string;
    value?: SubscriptionPlan;
  }>({});
  const [name, setName] = useState('');
  const [entitlement, setEntitlement] = useState<
    ResolvedEntitlement | undefined
  >(undefined);
  const currentDate = new Date();
  const [startDate, setStartDate] = useState<string | undefined>(
    format(currentDate, 'yyyy-MM-dd'),
  );
  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);
  const [revocationDate, setRevocationDate] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const name = getDefaultName(plan.value);
    const entitlement = getDefaultEntitlement(plan.value);
    name && setName(name);
    entitlement && setEntitlement(entitlement);
    plan.value && setInputLock(false);
  }, [plan]);

  const onConfirmClick = async () => {
    let expiry = 0;
    if (expiryDate) {
      const expiryDateObj = new Date(`${expiryDate}`);
      expiry = Math.ceil(expiryDateObj.getTime() / 1000);
    }
    let revocation = 0;
    if (revocationDate) {
      const revocationDateObj = new Date(`${revocationDate}`);
      revocation = Math.ceil(revocationDateObj.getTime() / 1000);
    }
    let started = 0;
    if (startDate) {
      const startDateObj = new Date(`${startDate}`);
      started = Math.ceil(startDateObj.getTime() / 1000);
    }
    try {
      await createSubscription(props.accountId, {
        plan: plan?.value as SubscriptionPlan,
        type: SubscriptionType.Internal,
        name: name,
        entitlement: entitlement as ResolvedEntitlement,
        expiry: expiry === 0 ? undefined : expiry,
        revocation: revocation === 0 ? undefined : revocation,
        started: started === 0 ? undefined : started,
      });
      props.onConfirm();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      onDismissClick();
    }
  };

  const onDismissClick = () => {
    setPlan({});
    setInputLock(true);
    setEntitlement(undefined);
    setExpiryDate('');
    setStartDate('');
    setRevocationDate('');
    props.onDismiss();
  };
  return (
    <ProcessingDialogModal
      header={`Create new Subscription`}
      onConfirm={onConfirmClick}
      onDismiss={onDismissClick}
      visible={props.visible}
    >
      <SpaceBetween direction="vertical" size="m">
        <Container>
          <SpaceBetween direction="vertical" size="l">
            <ColumnLayout columns={1}>
              <FormField label="Plan">
                <Select
                  selectedOption={plan}
                  onChange={({ detail }) =>
                    setPlan({
                      label: detail.selectedOption.label,
                      value: detail.selectedOption.value as SubscriptionPlan,
                    })
                  }
                  options={[
                    { label: 'Free Trial', value: 'FreeTrial' },
                    { label: 'Guided POC', value: 'GuidedPOC' },
                    { label: 'Demo', value: 'Demo' },
                    { label: 'Starter', value: 'Starter' },
                    { label: 'Business', value: 'Business' },
                    { label: 'Enterprise', value: 'Enterprise' },
                  ]}
                />
              </FormField>
              <FormField
                label="Type"
                constraintText="Only Internal type is supported."
              >
                <Input disabled={true} value={'Internal'} />
              </FormField>
              <FormField label="Name">
                <Input
                  value={name}
                  onChange={({ detail }) => setName(detail.value)}
                  disabled={inputLock}
                  placeholder="Select Plan"
                />
              </FormField>
              <Header variant="h3">Entitlement</Header>
              <FormField label="Features">
                <SpaceBetween direction="horizontal" size="s">
                  <Checkbox
                    checked={
                      (entitlement?.features || []).includes('request_addon') ||
                      false
                    }
                    onChange={(event) => {
                      if (event.detail.checked) {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          features: (e?.features || []).concat('request_addon'),
                        }));
                      } else {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          features: (e?.features || []).filter(
                            (f) => f !== 'request_addon',
                          ),
                        }));
                      }
                    }}
                  >
                    Can request Addon
                  </Checkbox>
                  <Checkbox
                    checked={
                      (entitlement?.features || []).includes('request_cloud') ||
                      false
                    }
                    onChange={(event) => {
                      if (event.detail.checked) {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          features: (e?.features || []).concat('request_cloud'),
                        }));
                      } else {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          features: (e?.features || []).filter(
                            (f) => f !== 'request_cloud',
                          ),
                        }));
                      }
                    }}
                  >
                    Can request Cloud
                  </Checkbox>
                </SpaceBetween>
              </FormField>
              <FormField label="Node Count">
                <SpaceBetween direction="vertical" size="xs">
                  <Input
                    type="number"
                    value={entitlement?.node_count?.limit.toString() || ''}
                    disabled={inputLock}
                    placeholder={inputLock ? 'Select Plan' : 'None'}
                    onChange={({ detail }) => {
                      setEntitlement({
                        ...entitlement,
                        node_count: {
                          ...entitlement?.node_count,
                          limit: parseInt(detail.value),
                          type:
                            entitlement?.node_count?.type ||
                            ('Soft' as PlanUsageLimitType), // provide a default value of 'Hard' for type when it is undefined
                        },
                      });
                    }}
                  />
                  <Select
                    selectedOption={{
                      label: entitlement?.node_count?.type || '',
                      value: entitlement?.node_count?.type || '',
                    }}
                    options={[
                      {
                        label: 'Hard',
                        value: 'Hard',
                      },
                      {
                        label: 'Soft',
                        value: 'Soft',
                      },
                    ]}
                    onChange={({ detail }) => {
                      setEntitlement({
                        ...entitlement,
                        node_count: {
                          ...entitlement?.node_count,
                          type: detail.selectedOption
                            .value as PlanUsageLimitType,
                          limit: entitlement?.node_count?.limit || 0, // provide a default value of 0 for limit when it is undefined
                        },
                      });
                    }}
                    disabled={inputLock}
                  />
                </SpaceBetween>
              </FormField>
              <ColumnLayout columns={2}>
                <FormField label="Upgrade Template Count">
                  <SpaceBetween direction="vertical" size="xs">
                    <Input
                      type="number"
                      value={
                        entitlement?.upgrade_template_count?.limit.toString() ||
                        ''
                      }
                      disabled={inputLock}
                      placeholder={inputLock ? 'Select Plan' : 'None'}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          upgrade_template_count: {
                            ...entitlement?.upgrade_plan_count,
                            limit: parseInt(detail.value),
                            type:
                              entitlement?.upgrade_template_count?.type ||
                              ('Hard' as PlanUsageLimitType), // provide a default value of 'Hard' for type when it is undefined
                          },
                        }));
                      }}
                    />
                    <Select
                      selectedOption={{
                        label: entitlement?.upgrade_template_count?.type || '',
                        value: entitlement?.upgrade_template_count?.type || '',
                      }}
                      options={[
                        {
                          label: 'Hard',
                          value: 'Hard',
                        },
                        {
                          label: 'Soft',
                          value: 'Soft',
                        },
                      ]}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          upgrade_template_count: {
                            ...entitlement?.upgrade_template_count,
                            type: detail.selectedOption
                              .value as PlanUsageLimitType,
                            limit:
                              entitlement?.upgrade_template_count?.limit || 0, // provide a default value of 0 for limit when it is undefined
                          },
                        }));
                      }}
                      disabled={inputLock}
                    />
                  </SpaceBetween>
                </FormField>
                <FormField label="Upgrade Plan Count">
                  <SpaceBetween direction="vertical" size="xs">
                    <Input
                      type="number"
                      value={
                        entitlement?.upgrade_plan_count?.limit.toString() || ''
                      }
                      disabled={inputLock}
                      placeholder={inputLock ? 'Select Plan' : 'None'}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          upgrade_plan_count: {
                            ...entitlement?.upgrade_plan_count,
                            limit: parseInt(detail.value),
                            type:
                              entitlement?.upgrade_plan_count?.type ||
                              ('Hard' as PlanUsageLimitType), // provide a default value of 'Hard' for type when it is undefined
                          },
                        }));
                      }}
                    />
                    <Select
                      selectedOption={{
                        label: entitlement?.upgrade_plan_count?.type || '',
                        value: entitlement?.upgrade_plan_count?.type || '',
                      }}
                      options={[
                        {
                          label: 'Hard',
                          value: 'Hard',
                        },
                        {
                          label: 'Soft',
                          value: 'Soft',
                        },
                      ]}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          upgrade_plan_count: {
                            ...entitlement?.upgrade_plan_count,
                            type: detail.selectedOption
                              .value as PlanUsageLimitType,
                            limit: entitlement?.upgrade_plan_count?.limit || 0, // provide a default value of 0 for limit when it is undefined
                          },
                        }));
                      }}
                      disabled={inputLock}
                    />
                  </SpaceBetween>
                </FormField>
              </ColumnLayout>
              <FormField label="Cluster Count">
                <SpaceBetween direction="vertical" size="xs">
                  <Input
                    type="number"
                    value={entitlement?.cluster_count?.limit.toString() || ''}
                    disabled={inputLock}
                    placeholder={inputLock ? 'Select Plan' : 'None'}
                    onChange={({ detail }) => {
                      setEntitlement((e) => ({
                        ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                        cluster_count: {
                          ...entitlement?.cluster_count,
                          limit: parseInt(detail.value),
                          type:
                            entitlement?.cluster_count?.type ||
                            ('Hard' as PlanUsageLimitType), // provide a default value of 'Hard' for type when it is undefined
                        },
                      }));
                    }}
                  />
                  <Select
                    selectedOption={{
                      label: entitlement?.cluster_count?.type || '',
                      value: entitlement?.cluster_count?.type || '',
                    }}
                    options={[
                      {
                        label: 'Hard',
                        value: 'Hard',
                      },
                      {
                        label: 'Soft',
                        value: 'Soft',
                      },
                    ]}
                    onChange={({ detail }) => {
                      setEntitlement((e) => ({
                        ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                        cluster_count: {
                          ...entitlement?.cluster_count,
                          type: detail.selectedOption
                            .value as PlanUsageLimitType,
                          limit: entitlement?.cluster_count?.limit || 0, // provide a default value of 0 for limit when it is undefined
                        },
                      }));
                    }}
                    disabled={inputLock}
                  />
                </SpaceBetween>
              </FormField>
            </ColumnLayout>
            <Header variant="h3">Subscription Dates</Header>
            <ColumnLayout columns={3}>
              <FormField
                label="Started"
                constraintText="Use YYYY/MM/DD format."
              >
                <DatePicker
                  disabled={inputLock}
                  value={startDate || ''}
                  onChange={(event) => setStartDate(event.detail.value)}
                  placeholder="None"
                />
              </FormField>
              <FormField label="Expiry" constraintText="Use YYYY/MM/DD format.">
                <DatePicker
                  disabled={inputLock}
                  value={expiryDate || ''}
                  onChange={(event) => setExpiryDate(event.detail.value)}
                  placeholder="None"
                />
              </FormField>
              <FormField
                label="Revocation"
                constraintText="Use YYYY/MM/DD format."
              >
                <DatePicker
                  disabled={inputLock}
                  value={revocationDate || ''}
                  onChange={(event) => setRevocationDate(event.detail.value)}
                  placeholder="None"
                />
              </FormField>
            </ColumnLayout>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </ProcessingDialogModal>
  );
}

function getDefaultName(
  plan: SubscriptionPlan | undefined,
): string | undefined {
  switch (plan) {
    case 'FreeTrial':
      return 'Free Trial';
    case 'GuidedPOC':
      return 'Guided POC';
    case 'Demo':
      return 'Demo';
    case 'Starter':
      return 'Starter';
    case 'Business':
      return 'Business';
    case 'Enterprise':
      return 'Enterprise';
  }
}

function getDefaultEntitlement(
  plan: SubscriptionPlan | undefined,
): ResolvedEntitlement | undefined {
  switch (plan) {
    case 'FreeTrial':
      return {
        node_count: {
          limit: 20,
          type: 'Soft',
        },
        features: ['request_addon', 'request_cloud'],
      };
    case 'GuidedPOC':
      return {
        node_count: {
          limit: 100,
          type: 'Soft',
        },
        upgrade_plan_count: {
          limit: 1,
          type: 'Soft',
        },
        upgrade_template_count: {
          limit: 1,
          type: 'Soft',
        },
        features: ['request_addon', 'request_cloud'],
      };
    case 'Demo':
      return {
        node_count: {
          limit: 0,
          type: 'Hard',
        },
      };
    case 'Starter':
      return {
        node_count: {
          limit: 100,
          type: 'Soft',
        },
        upgrade_plan_count: {
          limit: 0,
          type: 'Hard',
        },
        upgrade_template_count: {
          limit: 0,
          type: 'Hard',
        },
        features: ['request_addon', 'request_cloud'],
      };
    case 'Business':
      return {
        node_count: {
          limit: 100,
          type: 'Soft',
        },
        upgrade_plan_count: {
          limit: 0,
          type: 'Soft',
        },
        upgrade_template_count: {
          limit: 0,
          type: 'Soft',
        },
        features: ['request_addon', 'request_cloud'],
      };
    case 'Enterprise':
      return {
        node_count: {
          limit: 500,
          type: 'Soft',
        },
        upgrade_plan_count: {
          limit: 50,
          type: 'Soft',
        },
        upgrade_template_count: {
          limit: 10,
          type: 'Soft',
        },
        features: ['request_addon', 'request_cloud'],
      };
  }
}
